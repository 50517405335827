import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../BoatOnComponent'
import dictionary from './Dictionary/BoatOnBlockDico'
import { Actions } from '../../../constants'
import TextField from '@material-ui/core/TextField'
import BoatOnNumberField from '../UsefullComponents/BoatOnNumberField'
import AddressSearch from '../UsefullComponents/AddressSearch'

const DisabledComponentNames = [
    'TextField',
    'Input',
    'Select',
    'Autocomplete',
    'BoatOnNumberField',
    'Checkbox',
    'AutoComplete',
    'AddressSearch',
    'MuiAutocomplete',
    'Switch',
    'Button',
    'MobileTimePicker',
]

const AccessTypes = {
    AVAILABLE: 'AVAILABLE',
    RESTRICTED: 'RESTRICTED',
    UNAVAILABLE: 'UNAVAILABLE',
}

class BoatOnBlock extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    _getAccessRights(permissions) {
        const { selectedGroup, onlyOnePermission, addedCondition } = this.props

        if (Array.isArray(permissions)) {
            let access = !onlyOnePermission
            for (const permission of permissions) {
                const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                    access =>
                        ((permission?.entityName &&
                            access?.bobPermission?.entity?.entityName ===
                                permission.entityName) ||
                            (permission?.pageTypeId &&
                                access?.bobPermission?.pageType?.id ===
                                    permission.pageTypeId)) &&
                        access?.bobPermission?.[permission.action] === true,
                )
                if (
                    !onlyOnePermission &&
                    !userAccessToBlock?.access &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(permission)))
                ) {
                    access = AccessTypes.UNAVAILABLE
                    break
                } else if (
                    onlyOnePermission &&
                    !userAccessToBlock?.access &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(permission)))
                ) {
                    access = AccessTypes.UNAVAILABLE
                } else if (
                    onlyOnePermission &&
                    userAccessToBlock?.access === true &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(permission)))
                ) {
                    access = AccessTypes.AVAILABLE
                    break
                }
            }
            return { access }
        } else if (
            (permissions?.entityName || permissions?.pageTypeId) &&
            permissions?.action
        ) {
            const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                access =>
                    ((permissions?.entityName &&
                        access?.bobPermission?.entity?.entityName ===
                            permissions.entityName) ||
                        (permissions?.pageTypeId &&
                            access?.bobPermission?.pageType?.id ===
                                permissions.pageTypeId)) &&
                    access?.bobPermission?.[permissions.action] === true,
            )
            if (userAccessToBlock?.access !== undefined)
                return {
                    access: userAccessToBlock.access
                        ? AccessTypes.AVAILABLE
                        : AccessTypes.RESTRICTED,
                }
            else if (permissions.action === Actions.Update) {
                const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                    access =>
                        ((permissions?.entityName &&
                            access?.bobPermission?.entity?.entityName ===
                                permissions.entityName) ||
                            (permissions?.pageTypeId &&
                                access?.bobPermission?.pageType?.id ===
                                    permissions.pageTypeId)) &&
                        access?.bobPermission?.[Actions.Read] === true,
                )
                if (userAccessToBlock?.access !== undefined)
                    return {
                        access: AccessTypes.RESTRICTED,
                    }
            }
        }
        return { error: true }
    }

    _canChildrenBeDisabled(children) {
        console.log("Début de _canChildrenBeDisabled");
        console.log(children);

        const findComponent = (child) => {
            // Si l'enfant est un Fragment, on descend dans ses enfants
            if (React.isValidElement(child) && child.type === React.Fragment) {
                console.log("Fragment trouvé, descente dans ses enfants");
                const result = React.Children.toArray(child.props.children).some(findComponent);
                console.log("Résultat après exploration du Fragment : ", result);
                return result;
            }

            // Vérifie si l'enfant est un composant valide
            if (React.isValidElement(child)) {
                console.log("Vérification de l'enfant :", child);

                // Si l'enfant est un TextField ou un BoatOnNumberField, on les traite de manière similaire
                if (child.type === TextField || child.type === BoatOnNumberField || child.type === AddressSearch) {
                    console.log("Composant trouvé (TextField ou BoatOnNumberField) !");
                    const isDatePicker =
                        child.props && (child.props.openTo || child.props.views);

                    console.log("Propriétés spécifiques du TextField ou BoatOnNumberField : ");
                    console.log("renderInput :", child.props?.renderInput);
                    console.log("slotProps.textField :", child.props?.slotProps?.textField);
                    console.log("isDatePicker :", isDatePicker);

                    return true;
                }

                // Pour d'autres composants (composants enfants), on continue la recherche récursive
                console.log("Pas un TextField ou BoatOnNumberField, exploration des enfants...");
                if (child.props && child.props.children) {
                    const childType = React.Children.map(child.props.children, findComponent);
                    console.log("Exploration des enfants terminée. Résultats :", childType);
                    if (childType && childType.length > 0) {
                        console.log("Enfant valide trouvé, retour du premier.");
                        return childType[0]; // Retourne le premier enfant valide trouvé
                    }
                }
            }

            // Si aucune condition particulière n'est rencontrée, on retourne une valeur par défaut
            console.log("Aucune condition spéciale rencontrée, retour du test final.");
            return this._checkIfComponentIsDisabled(child);
        };

        const result = findComponent(children);
        console.log("Résultat final de _canChildrenBeDisabled : ", result);
        return result;
    }

    _checkIfComponentIsDisabled(child) {
        console.log("Vérification de la désactivation pour le composant", child.type);
        console.log("Vérification du composant", child.type);

        // Si c'est un composant React avec un type valide, on vérifie s'il est dans DisabledComponentNames
        if (child && child.type) {
            // Vérification si le composant appartient à DisabledComponentNames
            const displayNameMatches = DisabledComponentNames.some((componentName) =>
                (child.type.displayName && child.type.displayName.includes(componentName)) ||
                (child.type.name && child.type.name.includes(componentName)) ||
                (child.type === TextField)  // Vérification directe du type pour TextField
            );
            console.log("Résultat de DisabledComponentNames", displayNameMatches);

            // Si le composant est explicitement désactivé, on retourne `true`
            return displayNameMatches || child.props?.disabled;
        }
        return false;
    }



    render() {
        const {
            children,
            blockProps,
            selectedGroup,
            childrenNoAccess,
            permissions,
        } = this.props

        if (!selectedGroup) {
            return <></>
        }

        if (permissions === null) {
            console.error(
                `You didnt set a definition of permissions for this block`,
            )
            return <></>
        }

        if (!children) {
            console.error(`The children is not defined`)
            return <></>
        }

        const { access, error } = this._getAccessRights(permissions)

        if (error) {
            console.error(`This permission doesn't exist`)
            if (childrenNoAccess) {
                return childrenNoAccess
            } else {
                return <></>
            }
        }

        switch (access) {
            case AccessTypes.AVAILABLE:
                if (blockProps) return <div {...blockProps}>{children}</div>
                return children
            case AccessTypes.RESTRICTED:
                console.log('je cherche au bon endroit')
                console.log(this._canChildrenBeDisabled(
                    children,
                ))
                console.log(children)
                const restrictedChildren = this._canChildrenBeDisabled(
                    children,
                ) ? (
                    React.cloneElement(children, {
                        disabled: true,
                    })
                ) : (
                    <></>
                )
                console.log(restrictedChildren)
                if (blockProps)
                    return <div {...blockProps}>{restrictedChildren}</div>
                return restrictedChildren
            case AccessTypes.UNAVAILABLE:
                return <></>
            default:
                return <></>
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        selectedGroup: state.block.selectedGroup,
        currentGroupId: state.group.currentGroupId,
        groupLoading: state.group.loading,
        groups: state.group.groupsMembers,
    }
}

export default connect(mapStateToProps)(BoatOnBlock)
