import React from 'react'
import TextField from '@material-ui/core/TextField'
import { fixIosHeaderBug } from '../../../utils/usefullFunctions'

/**
 * Encapsulation of TextField from mui to convert it in input number
 * @class BoatOnNumberField
 * @extends {BoatOnNumberField}
 *
 * @region ```
 * Customs Props
 * ```
 *
 * @param {Number} min - minimal value possible.
 * @param {Number} max - maximal value possible.
 * @param {Boolean} isDecimal - authorize number with . or ,
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 17/04/2024 - 16:01
 * @author Samuel.C
 */
class BoatOnNumberField extends React.Component {
    constructor(props) {
        super(props)

        this.onKeyDown = this.onKeyDown.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onInput = this.onInput.bind(this)
        this.incrementValue = this.incrementValue.bind(this)
        this.decrementValue = this.decrementValue.bind(this)
    }

    onKeyDown(e) {
        const { isDecimal } = this.props

        // Ignorer les combinaisons de touches avec 'Ctrl' ou 'Cmd' (pour autoriser copier-coller)
        if (e.ctrlKey || e.metaKey) {
            return
        }

        // Autoriser certaines touches spécifiques
        const allowedKeys = [
            'Backspace',
            'ArrowUp',
            'ArrowDown',
            'Tab',
            'Enter',
        ]
        if (allowedKeys.includes(e.key)) {
            return // Ne pas bloquer ces touches
        }

        // Autoriser '-' uniquement au début ou si le champ est vide
        const cursorPosition = e.target.selectionStart
        if (
            e.key === '-' &&
            (cursorPosition === 0 || e.target.value.length === 0)
        ) {
            return // Permet d'ajouter '-' au début ou si le champ est vide
        }

        // Empêcher les autres touches non numériques ou décimales si non autorisées
        if (
            (/[^0-9,\.]/.test(e.key) && e.key !== '-') || // Empêcher les caractères non autorisés
            (!isDecimal && (e.key === '.' || e.key === ','))
        ) {
            e.preventDefault() // Bloquer les caractères non autorisés
        }
    }

    onChange(e) {
        const { onChange, min, max } = this.props
        let value = e.target.value
        value = value.replace(',', '.')

        // Autoriser '-' comme valeur temporaire
        if (value === '-') {
            if (onChange) onChange(e)
            return // Sortir pour éviter des erreurs
        }

        // Convertir en nombre uniquement si ce n'est pas '-'
        const numericValue = parseFloat(value)
        if (isNaN(numericValue) && value !== '') {
            return // Ne pas permettre les valeurs non numériques sauf '-'
        }

        // Vérifier que la valeur est dans la plage (si min/max sont définis)
        if (min !== undefined && numericValue < min) return
        if (max !== undefined && numericValue > max) return

        // Propager la modification si tout est correct
        if (onChange) onChange(e)
    }

    onInput(e) {
        const { min, max } = this.props
        let value = e.target.value
        value = value.replace(',', '.')

        // Autoriser '-' uniquement si c'est le premier caractère
        if (value.startsWith('-')) {
            if (value.length === 1) {
                return // Si le seul caractère est '-', on laisse tel quel
            }
        }

        // Vérifier que la valeur est dans la plage (si min/max sont définis)
        const numericValue = parseFloat(value)
        if (!isNaN(numericValue)) {
            if (min !== undefined && numericValue < min) {
                value = min
            }
            if (max !== undefined && numericValue > max) {
                value = max
            }
        }

        e.target.value = value
        this.onChange(e) // Appeler la fonction onChange pour propager la modification
    }

    incrementValue() {
        const { value } = this.props
        let event = {
            target: {
                value: 0,
            },
        }

        if (value !== '')
            event.target.value = parseFloat(value.replace(',', '.')) + 1

        this.onChange(event)
    }

    decrementValue() {
        const { value } = this.props
        let event = {
            target: {
                value: 0,
            },
        }

        if (value !== '')
            event.target.value = parseFloat(value.replace(',', '.')) - 1

        this.onChange(event)
    }

    onPaste(e) {
        const paste = (e.clipboardData || window.clipboardData).getData('text')
        // Valider le contenu du presse-papiers
        const regex = /^[0-9,.]+$/

        if (!regex.test(paste)) {
            e.preventDefault()
        }
    }

    render() {
        const textFieldProps = { ...this.props }

        //remove error from console
        delete textFieldProps.isDecimal

        return (
            <TextField
                {...textFieldProps}
                onKeyDown={this.onKeyDown}
                onPaste={this.onPaste}
                onInput={this.onInput}
                inputProps={{
                    inputMode: 'decimal',
                    ...this.props.inputProps,
                }}
                onChange={this.onChange}
                onBlur={() => fixIosHeaderBug()}
            />
        )
    }
}
BoatOnNumberField.displayName = 'BoatOnNumberField'; // Définir explicitement displayName
export default BoatOnNumberField
