import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import L from 'leaflet'
import BobFilter from '../BobFilter'
import { Button as ButtonBON } from '../../../common/BoatOnButton'
import BoatOnLoading from '../../../common/UsefullComponents/BoatOnLoading'
import BoatOnSpeedometer from '../../../common/BoatOnSpeedometer'
import LogBookCreateNav from './Modals/LogBookCreateNav'

import { getTranslatedTitleFromEvent } from '../Utils'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import { API_URL } from '../../../../services/config.service'

import {
    Route as RouteIcon,
    Close as CloseIcon,
    Place as PlaceIcon,
    ExpandMore as ExpandMoreIcon,
    Person as PersonIcon,
} from '@mui/icons-material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

import { getNavigationsFromGroup, initBobWithBoat } from '../../../../actions'
import {
    getLastAisData,
    getAisBoatsFromGroupId,
    getAisByNavigation,
    getWeatherByCoordinates,
} from '../../../../actions/bob/navigationApiData.actions'

import {
    getDistanceFromCoordinates,
    haversineDistance,
} from './NavigationPage/Utils'

import {
    getFillingLevels,
    getWorkingHours,
    getNavigationDurationTime,
    getTextFromAddress,
} from './Utils/eventsFilter'

import navigationIcon from '../../../../images/logbook/icons/navigation.svg'
import headArrowIcon from '../../../../images/logbook/icons/headArrow.svg'
import sun from '../../../../images/logbook/icons/Sun.svg'
import wind from '../../../../images/logbook/icons/wind.svg'
import seaLevel from '../../../../images/logbook/icons/seaLevel.svg'

import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/LogBookMapDico'
import styles from './Styles/LogBookMapCss'

/**
 * page of the map with all the boats
 *
 * @class LogBookMap
 * @extends {BoatOnComponent}
 *
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 19/08/2024 - 14:32
 * @author Samuel.C
 */
class LogBookMap extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedModal: false,

            mapInfos: null,
            boats: null,
            actualiseIds: [],
            zoomLevel: null,
            drawAis: false,

            selectedBoat: null,
            equipmentInUse: [],
            equipmentCapacities: [],
        }

        this.mapRef = React.createRef()
        this.timeout = null

        this.handleZoomEnd = this.handleZoomEnd.bind(this)
        this.switchDrawAis = this.switchDrawAis.bind(this)
        this.openNewNavigation = this.openNewNavigation.bind(this)
        this.closeNewNavigation = this.closeNewNavigation.bind(this)
    }

    componentDidMount() {
        const { dispatch, groupId, events, navigations } = this.props

        this._updateAis()

        if (!navigations) dispatch(getNavigationsFromGroup(groupId))
        if (events && events.length > 0) this._initState()
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch, boats, user, events, lastAis } = this.props
        const { selectedBoat, zoomLevel } = this.state

        if (
            (prevProps.loadingNav !== 0 && this.props.loadingNav === 0) ||
            (prevProps.loadingAis !== 0 && this.props.loadingAis === 0) ||
            JSON.stringify(prevProps.bobFilter) !==
                JSON.stringify(this.props.bobFilter)
        ) {
            this._getBoatsCoordinates()
        }

        if (
            this.state.boats &&
            this.state.boats.length !== prevState.boats?.length
        ) {
            this.setState({ mapInfos: this._getMapInfos() })
        }

        if (this.mapRef?.current && !zoomLevel) {
            const map = this.mapRef.current

            if (map) {
                this.setState({ zoomLevel: map.getZoom() })
                map.on('zoomend', this.handleZoomEnd)
            }
        }

        const boatIdSelect = localStorage.getItem('boatIdSelect')
        if (selectedBoat && boatIdSelect !== `${selectedBoat}`) {
            localStorage.setItem('boatIdSelect', selectedBoat || -1)
            dispatch(
                initBobWithBoat({
                    user,
                    boatId: selectedBoat,
                }),
            )
        }

        if (events && events !== prevProps.events) this._initState()

        if (prevState.selectedBoat !== selectedBoat && selectedBoat !== null) {
            const boat = boats.find(b => b.id === selectedBoat)
            const lastNav = this._getLastNavigation(boat.id)
            const ais = lastAis.find(
                last => last.mmsi.toString() === boat?.mmsi,
            )

            if (lastNav) dispatch(getAisByNavigation(lastNav.id))

            if (boat?.mmsi && ais && ais.latitude && ais.longitude)
                dispatch(getWeatherByCoordinates(ais.latitude, ais.longitude))
            else if (lastNav) {
                let lastAddress = lastNav.arrivalAddress

                if (
                    !lastAddress &&
                    lastNav?.stopOvers &&
                    lastNav?.stopOvers.length > 0
                ) {
                    const lastStop = lastNav.stopOvers.reduce((last, data) => {
                        if (!last) return data
                        if (
                            new Date(last.delimitedDate.startDate) <
                            new Date(data.delimitedDate.startDate)
                        )
                            return data
                        return last
                    }, null)

                    if (lastStop) lastAddress = lastStop.address
                }

                if (!lastAddress) lastAddress = lastNav.departureAddress

                if (
                    lastAddress &&
                    lastAddress.latitude &&
                    lastAddress.longitude
                )
                    dispatch(
                        getWeatherByCoordinates(
                            lastAddress?.latitude,
                            lastAddress?.longitude,
                        ),
                    )
            } else if (boat?.address?.latitude && boat?.address?.longitude) {
                dispatch(
                    getWeatherByCoordinates(
                        boat.address.latitude,
                        boat.address.longitude,
                    ),
                )
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
        const map = this.mapRef?.current
        if (map !== null) {
            map.off('zoomend', this.handleZoomEnd)
        }
    }

    _updateAis() {
        const { dispatch, groupId } = this.props

        dispatch(getAisBoatsFromGroupId(groupId))
        this.timeout = setTimeout(() => this._updateAis(), 10 * 60 * 1000)
    }

    _initState() {
        const { events, userEquipments } = this.props

        const workingHours = getWorkingHours(events)
        const fillingLevels = getFillingLevels(events, userEquipments)

        this.setState({
            equipmentInUse: workingHours.equipmentInUse,
            equipmentCapacities: fillingLevels.bobEventUsed,
        })
    }

    _getLastNavigation(boatId) {
        const { navigations } = this.props

        if (!navigations) return null

        const currentNavigations = navigations.filter(
            navigation => navigation.boat.id === boatId,
        )
        const lastNavigation = currentNavigations.reduce((save, nav) => {
            if (save === null) return nav
            if (
                (!save.delimitedDate.endDate && nav.delimitedDate.endDate) ||
                (save.delimitedDate.endDate &&
                    nav.delimitedDate.endDate &&
                    new Date(save.delimitedDate.endDate) <
                        new Date(nav.delimitedDate.endDate))
            )
                return nav
            return save
        }, null)

        return lastNavigation
    }

    _getBoatsCoordinates() {
        const { dispatch, boats, lastAis } = this.props
        console.log(lastAis)
        const formattedBoats = boats
            .map(boat => {
                if (boat.mmsi && /^\d{9}$/.test(boat.mmsi)) {
                    const currentAis = lastAis.find(
                        ais => ais.mmsi.toString() === boat.mmsi,
                    )
                    if (!currentAis) {
                        dispatch(getLastAisData(boat.mmsi))
                    } else if (currentAis.latitude && currentAis.longitude) {
                        return {
                            ...boat,
                            lastCoordinates: {
                                speed: currentAis.speed,
                                heading: currentAis.heading,
                                lastSignalDate: currentAis.timestamp,
                                latitude: parseFloat(currentAis.latitude),
                                longitude: parseFloat(currentAis.longitude),
                            },
                        }
                    }
                }

                const lastNavigation = this._getLastNavigation(boat.id)
                if (lastNavigation) {
                    if (lastNavigation.arrivalAddress) {
                        return {
                            ...boat,
                            lastCoordinates: {
                                heading: 0,
                                latitude: parseFloat(
                                    lastNavigation.arrivalAddress.latitude,
                                ),
                                longitude: parseFloat(
                                    lastNavigation.arrivalAddress.longitude,
                                ),
                            },
                        }
                    } else if (lastNavigation.stopOvers?.length > 0) {
                        const lastStopOver = lastNavigation.stopOvers.reduce(
                            (save, stop) => {
                                if (save === null) return stop
                                if (!save.delimitedDate.endDate) return stop
                                if (
                                    stop.delimitedDate.endDate &&
                                    new Date(save.delimitedDate.endDate) >
                                        new Date(stop.delimitedDate.endDate)
                                )
                                    return stop
                            },
                            null,
                        )
                        if (lastStopOver)
                            return {
                                ...boat,
                                lastCoordinates: {
                                    heading: 0,
                                    latitude: parseFloat(
                                        lastStopOver.address.latitude,
                                    ),
                                    longitude: parseFloat(
                                        lastNavigation.address.longitude,
                                    ),
                                },
                            }
                    } else {
                        return {
                            ...boat,
                            lastCoordinates: {
                                heading: 0,
                                latitude: parseFloat(
                                    lastNavigation.departureAddress.latitude,
                                ),
                                longitude: parseFloat(
                                    lastNavigation.departureAddress.longitude,
                                ),
                            },
                        }
                    }
                } else if (boat.address?.longitude && boat.address?.latitude) {
                    return {
                        ...boat,
                        lastCoordinates: {
                            heading: 0,
                            latitude: parseFloat(boat.address.latitude),
                            longitude: parseFloat(boat.address.longitude),
                        },
                    }
                }

                return null
            })
            .filter(Boolean)

        this.setState({
            boats: formattedBoats,
        })
    }

    _createRotatedIcon(img, heading, opt) {
        return new L.DivIcon({
            html: `
                <div
                    style="
                        transform: rotate(${heading || 0}deg);
                    "
                >
                    <img
                        src="${img}"
                        style="width: 100%; height: 100%;"
                    />
                </div>
            `,
            iconSize: opt?.iconSize ?? [30, 30],
            iconAnchor: opt?.iconAnchor ?? [15, 15],
            popupAnchor: opt?.popupAnchor ?? [15, 15],
            className: opt?.className ?? undefined,
        })
    }

    _rotatableMarker(img, position, heading, opt, markerCallback) {
        const icon = this._createRotatedIcon(img, heading, opt)

        return (
            <Marker
                position={position}
                icon={icon}
                eventHandlers={{
                    click: markerCallback ? markerCallback : undefined,
                }}
            />
        )
    }

    _clusterBoats(boats, zoomLevel) {
        const clusters = []
        const pixelDistance = Math.max(120 - zoomLevel * 5, 10)

        boats.forEach(boat => {
            let added = false

            for (const cluster of clusters) {
                if (this._isCloseEnough(boat, cluster[0], pixelDistance)) {
                    cluster.push(boat)
                    added = true
                    break
                }
            }

            if (!added) clusters.push([boat])
        })

        return clusters
    }

    _isCloseEnough(boat1, boat2, pixelDistance) {
        const map = this.mapRef.current
        const pos1 = map.latLngToContainerPoint([
            boat1.lastCoordinates.latitude,
            boat1.lastCoordinates.longitude,
        ])
        const pos2 = map.latLngToContainerPoint([
            boat2.lastCoordinates.latitude,
            boat2.lastCoordinates.longitude,
        ])

        return pos1.distanceTo(pos2) <= pixelDistance
    }

    _createClusterMarker(position, count, cluster) {
        const { classes } = this.props
        const clusterIcon = new L.DivIcon({
            html: `
                <div
                    class="${this.props.classes.clusterMarker}"
                >
                    ${count}
                </div>
            `,
            className: 'cluster-marker',
            iconSize: L.point(45, 45),
            popupAnchor: [0, -22.5],
        })

        return (
            <Marker position={position} icon={clusterIcon}>
                <Popup className={classes.popupCluster}>
                    {cluster.map(boat => (
                        <div
                            className={classes.popupClusterItem}
                            onClick={() =>
                                this.setState({ selectedBoat: boat.id })
                            }
                        >
                            {boat.name}
                        </div>
                    ))}
                </Popup>
            </Marker>
        )
    }

    _getBoatsMarkers() {
        const { boats, zoomLevel } = this.state

        const clusters =
            boats && zoomLevel ? this._clusterBoats(boats, zoomLevel) : []

        return clusters.map(cluster => {
            if (cluster.length === 1) {
                const boat = cluster[0]

                return this._rotatableMarker(
                    navigationIcon,
                    [
                        boat.lastCoordinates.latitude,
                        boat.lastCoordinates.longitude,
                    ],
                    boat.lastCoordinates.heading,
                    null,
                    () => this.setState({ selectedBoat: boat.id }),
                )
            } else {
                const avgLat =
                    cluster.reduce(
                        (sum, boat) => sum + boat.lastCoordinates.latitude,
                        0,
                    ) / cluster.length
                const avgLon =
                    cluster.reduce(
                        (sum, boat) => sum + boat.lastCoordinates.longitude,
                        0,
                    ) / cluster.length

                return this._createClusterMarker(
                    [avgLat, avgLon],
                    cluster.length,
                    cluster,
                )
            }
        })
    }

    _calculatePointsToDisplay(totalPoints, totalDistance) {
        const { zoomLevel } = this.state

        const minZoom = 0
        const maxZoom = 18
        const minPoints = 10
        const maxPoints = totalPoints

        const normalizedZoom = (zoomLevel - minZoom) / (maxZoom - minZoom)
        const zoomFactor = Math.pow(normalizedZoom, 3)

        let pointsToDisplay = Math.floor(
            minPoints + zoomFactor * (maxPoints - minPoints),
        )

        // For instance, if the distance is large, you might want to display fewer points at lower zoom levels
        // Example threshold: 1000 units
        const distanceFactor = totalDistance > 1000 ? 0.75 : 1
        pointsToDisplay = Math.floor(pointsToDisplay * distanceFactor)

        // Ensure we don't exceed the available number of points
        pointsToDisplay = Math.min(pointsToDisplay, totalPoints)

        return pointsToDisplay
    }

    _getBoatsTraces() {
        const { classes, aisByGroup } = this.props
        const { boats } = this.state
        console.log(aisByGroup)
        return aisByGroup
            .map(item => {
                if (!item.data || item.data?.length === 0) return null

                const currentBoat = boats.find(boat => boat.id === item.boat.id)
                const lastNav = this._getLastNavigation(currentBoat.id)
                const aisPoints = []
                const today = new Date()

                item.data
                    .filter(ais => {
                        let minDate =
                            lastNav?.delimitedDate?.startDate &&
                            !lastNav?.delimitedDate?.endDate
                                ? new Date(lastNav.delimitedDate.startDate)
                                : new Date().setDate(today.getDate() - 1)
                        let maxDate = today

                        return (
                            new Date(ais.createdAt).getTime() >
                                new Date(minDate).getTime() &&
                            new Date(ais.createdAt).getTime() <
                                new Date(maxDate).getTime()
                        )
                    })
                    .map(ais => {
                        aisPoints.push({
                            coordinates: [ais.latitude, ais.longitude],
                            heading: ais.heading,
                            createdAt: ais.createdAt,
                        })
                    })

                // calculer la distance totale
                const totalDistance = getDistanceFromCoordinates(
                    aisPoints.map(ais => ais.coordinates),
                )

                // calculer en fonction de la distance et du zoom le nombre de markers => le nombre de point necessaire
                const numberOfPoints = this._calculatePointsToDisplay(
                    aisPoints.length,
                    totalDistance,
                )

                // calculer la distance entre chaque points
                const distancePerPoint = totalDistance / numberOfPoints

                // récupérer les points le plus proches des distances
                const arrowPoints = []
                let numberOfArrow = 1
                let distance = 0

                aisPoints.forEach((ais, index) => {
                    if (index === 0) return

                    distance += haversineDistance(
                        aisPoints[index - 1].coordinates,
                        ais.coordinates,
                    )

                    if (distance >= distancePerPoint * numberOfArrow) {
                        arrowPoints.push(ais)
                        numberOfArrow += 1
                    }
                })

                // dessiner les fleches
                const arrows = arrowPoints.map(arrow =>
                    this._rotatableMarker(
                        headArrowIcon,
                        arrow.coordinates,
                        arrow.heading,
                        {
                            iconSize: [10, 10],
                            iconAnchor: [5, 9],
                            className: classes.notClickable,
                        },
                    ),
                )

                return [
                    <Polyline
                        pathOptions={{
                            color: '#303F9F',
                            weight: 2,
                            bubblingMouseEvents: false,
                        }}
                        positions={aisPoints.map(ais => ais.coordinates)}
                        className={classes.notClickable}
                    />,
                    ...arrows,
                ]
            })
            .filter(Boolean)
    }

    _getMapInfos() {
        const { boats } = this.state

        const mapInfos = {
            points: [],
            minPoint: {
                lat: null,
                lon: null,
            },
            maxPoint: {
                lat: null,
                lon: null,
            },
            center: {
                lat: null,
                lon: null,
            },
        }

        ;(boats || []).forEach(boat => {
            mapInfos.points.push([
                boat.lastCoordinates.latitude,
                boat.lastCoordinates.longitude,
            ])

            if (mapInfos.minPoint.lat === null) {
                mapInfos.minPoint.lat = boat.lastCoordinates.latitude
                mapInfos.minPoint.lon = boat.lastCoordinates.longitude
                mapInfos.maxPoint.lat = boat.lastCoordinates.latitude
                mapInfos.maxPoint.lon = boat.lastCoordinates.longitude
                return
            }

            if (mapInfos.minPoint.lat > boat.lastCoordinates.latitude)
                mapInfos.minPoint.lat = boat.lastCoordinates.latitude
            if (mapInfos.minPoint.lon > boat.lastCoordinates.longitude)
                mapInfos.minPoint.lon = boat.lastCoordinates.longitude
            if (mapInfos.maxPoint.lat < boat.lastCoordinates.latitude)
                mapInfos.maxPoint.lat = boat.lastCoordinates.latitude
            if (mapInfos.maxPoint.lon < boat.lastCoordinates.longitude)
                mapInfos.maxPoint.lon = boat.lastCoordinates.longitude
        })

        mapInfos.center = {
            lon: mapInfos.maxPoint.lon - mapInfos.minPoint.lon,
            lat: mapInfos.maxPoint.lat - mapInfos.minPoint.lat,
        }

        return mapInfos
    }

    handleZoomEnd() {
        const map = this.mapRef.current
        if (map) this.setState({ zoomLevel: map.getZoom() })
    }

    switchDrawAis() {
        const { drawAis } = this.state

        this.setState({ drawAis: !drawAis })
    }

    closeNewNavigation() {
        this.setState({ selectedModal: false })
    }

    openNewNavigation() {
        this.setState({ selectedModal: 'create' })
    }

    _endFirstLoad() {
        this.setState({ firstLoading: false })
    }

    _renderStepperModal(lastNav, departure) {
        const { classes } = this.props

        if (
            departure
                ? !lastNav?.delimitedDate?.startDate
                : !lastNav?.delimitedDate?.endDate
        )
            return null

        const date = departure
            ? new Date(lastNav.delimitedDate.startDate)
            : new Date(lastNav.delimitedDate.endDate)
        const displayedDate = `${
            date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
        }/${
            date.getMonth() <= 9 ? '0' + date.getMonth() : date.getMonth()
        }/${date.getFullYear()}`
        const displayedHours = `${
            date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()
        }h${
            date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
        }`

        return (
            <div className={classes.stepperItem}>
                <div className={classes.stepperDotContainer}>
                    <div
                        className={
                            departure
                                ? classes.stepperDot
                                : `${classes.stepperDot} ${classes.stepperRoute}`
                        }
                    />
                </div>
                <div className={classes.stepperData}>
                    <div className={classes.stepperDate}>{displayedDate}</div>
                    <div className={classes.stepperHours}>{displayedHours}</div>
                    <div className={classes.stepperAddress}>
                        {departure
                            ? this.displayText('departure')
                            : this.displayText('arrival')}
                        <PlaceIcon className={classes.stepperAddressIcon} />
                        <div className={classes.stepperAddressContainer}>
                            {getTextFromAddress(
                                departure
                                    ? lastNav.departureAddress
                                    : lastNav.arrivalAddress,
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _renderBadge(user, isCaptain) {
        const { classes } = this.props

        return (
            <div
                className={classes.userBadgeContainer}
                title={
                    user?.firstName && user?.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : user?.mail
                }
            >
                <div
                    className={
                        isCaptain
                            ? `${classes.userBadge} ${classes.captainBadge}`
                            : classes.userBadge
                    }
                >
                    {isCaptain ? (
                        <>C</>
                    ) : user?.firstName ? (
                        `${user.firstName[0]}${user.lastName[0]}`
                    ) : (
                        <PersonIcon className={classes.userBadgeIcon} />
                    )}
                </div>
                {user?.firstName && user?.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : user?.mail}
            </div>
        )
    }

    _renderCrew(lastNav) {
        const { classes, linkedUsers } = this.props
        const linkedCaptain = linkedUsers.find(
            user => user.id === lastNav.captainLinkId,
        )
        const captain = linkedCaptain?.user
            ? linkedCaptain.user
            : linkedCaptain?.userSubscribe
        const members = []

        lastNav.navigationMembers.forEach(member => {
            const linkedUser = linkedUsers.find(
                user => user.id === member.userLinkId,
            )

            if (linkedUser)
                members.push(
                    linkedUser.user
                        ? linkedUser.user
                        : linkedUser.userSubscribe,
                )
        })

        return (
            <div className={classes.crewContainer}>
                <div className={classes.crewItem}>
                    {this._renderBadge(captain, true)}
                </div>
                {members.map(member => (
                    <div className={classes.crewItem}>
                        {this._renderBadge(member, false)}
                    </div>
                ))}
            </div>
        )
    }

    _renderStopovers(lastNav) {
        const { classes } = this.props

        return (
            <div className={classes.stopoversContainer}>
                {lastNav.stopOvers.map(stop => {
                    const date = new Date(stop.delimitedDate.startDate)
                    const formattedDate = new Intl.DateTimeFormat(
                        this.getContext(),
                        {
                            day: 'numeric',
                            month: 'long',
                            hour: '2-digit',
                            minute: '2-digit',
                            hourCycle: 'h23',
                        },
                    ).format(date)

                    return (
                        <div className={classes.stopoversItem}>
                            <div className={classes.stopoversTitle}>
                                {getTextFromAddress(stop.address)}
                            </div>
                            <div className={classes.stopoversDate}>
                                {formattedDate
                                    .replace(':', 'h')
                                    .replace(' à', ',')
                                    .replace(' at', ',')}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    _renderWeather() {
        const { classes, currentWeather } = this.props

        return (
            <div className={classes.weatherContainer}>
                <div className={classes.weatherData}>
                    {currentWeather.airTemperature} °C
                    <img className={classes.weatherIcon} src={sun} />
                </div>
                <div className={classes.weatherData}>
                    {currentWeather.windSpeed} kn
                    <img className={classes.weatherIcon} src={wind} />
                </div>
                <div className={classes.weatherData}>
                    {currentWeather.waveHeight} m
                    <img className={classes.weatherIcon} src={seaLevel} />
                </div>
            </div>
        )
    }

    _renderFillingLevels() {
        const { classes, context, events } = this.props
        const { equipmentCapacities } = this.state

        if (!events) return null

        return (
            <div className={classes.fLContainer}>
                {equipmentCapacities
                    .sort((a, b) => {
                        const aName = getTranslatedTitleFromEvent(this, a)
                        const bName = getTranslatedTitleFromEvent(this, b)

                        if (!aName) return 1
                        if (!bName) return -1

                        return aName.localeCompare(bName)
                    })
                    .map((event, index) => {
                        const equipment = event.detail?.equipment
                        const type = equipment?.equipmentFamily?.type
                        const optionnalDetail = event.detail?.optionnalDetail

                        if (!equipment) return null

                        const imgSrc = type?.image
                            ? require(`images/${type?.image}`)
                            : null
                        const value = optionnalDetail?.fillingLevel ?? 0
                        const maxValue = optionnalDetail?.capacity ?? null
                        const unit =
                            equipment.equipmentType?.optionnalDetailActivated
                                ?.unit

                        return (
                            <div className={classes.flItem} key={`fl-${index}`}>
                                <div className={classes.flTitle}>
                                    <div className={classes.flIconContainer}>
                                        <img
                                            src={imgSrc}
                                            alt={
                                                type?.translation[context] || ''
                                            }
                                            className={classes.flIcon}
                                        />
                                    </div>
                                    {getTranslatedTitleFromEvent(this, event)}
                                </div>
                                <div className={classes.flDataContainer}>
                                    <div style={{ flex: 1, padding: 10 }}>
                                        <BoatOnSpeedometer
                                            inverted={
                                                !equipment.isInvertedFillingLevel
                                            }
                                            forceNormalRender
                                            removeGraduate
                                            minValue={0}
                                            maxValue={100}
                                            value={value}
                                        />
                                    </div>
                                    <div
                                        className={classes.flData}
                                        style={{
                                            color: equipment.isInvertedFillingLevel
                                                ? value > 75
                                                    ? 'red'
                                                    : 'black'
                                                : value < 25
                                                ? 'red'
                                                : 'black',
                                        }}
                                    >
                                        {value}%
                                        <div className={classes.flDataMax}>
                                            {maxValue !== null
                                                ? `${maxValue} ${unit}`
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        )
    }

    _renderWorkingHours() {
        const { classes, context } = this.props
        const { equipmentInUse } = this.state

        return (
            <div className={classes.whContainer}>
                {equipmentInUse
                    .sort((a, b) => {
                        const aName = getTranslatedTitleFromEvent(this, a)
                        const bName = getTranslatedTitleFromEvent(this, b)

                        if (!aName) return 1
                        if (!bName) return -1

                        return aName.localeCompare(bName)
                    })
                    .map((event, index) => {
                        const equipment = event.detail?.equipment || null
                        const type = equipment.equipmentFamily?.type || null

                        if (!equipment) return null

                        const imgSrc = type?.image
                            ? require(`images/${type?.image}`)
                            : null

                        const value = event?.detail?.hourActual ?? 0

                        return (
                            <div
                                className={classes.whItem}
                                key={`whItem-${index}`}
                            >
                                <img
                                    src={imgSrc}
                                    alt={type?.translation[context] || ''}
                                    className={classes.whIcon}
                                />
                                <div className={classes.whDataContainer}>
                                    <div className={classes.whTitle}>
                                        {getTranslatedTitleFromEvent(
                                            this,
                                            event,
                                        )}
                                    </div>
                                    <div className={classes.whValue}>
                                        {`${value} h`}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        )
    }

    _renderBoatInfos() {
        const { classes, boat } = this.props

        if (!boat) return null

        const values = [
            {
                label: this.displayText('boatType'),
                value: this.displayTextApi(boat.boatType.translation),
            },
            {
                label: this.displayText('boatYear'),
                value: boat.year,
            },
            {
                label: this.displayText('boatMaker'),
                value: boat.maker,
            },
            {
                label: this.displayText('boatModel'),
                value: boat.model,
            },
            {
                label: this.displayText('landingStage'),
                value: boat.landingStage,
            },
            {
                label: this.displayText('pontoon'),
                value: boat.pontoon,
            },
            {
                label: this.displayText('serialNumber'),
                value: boat.serialNumber,
            },
            {
                label: this.displayText('mmsiNumber'),
                value: boat.mmsi,
            },
            {
                label: this.displayText('boatAddress'),
                value: boat.address?.fullText,
            },
        ]

        return (
            <div className={classes.boatInfosContainer}>
                {values.map((item, index) => (
                    <div
                        className={classes.boatInfosItem}
                        key={`boatInfo-${index}`}
                    >
                        <div className={classes.boatInfosTitle}>
                            {item.label}
                        </div>
                        <div className={classes.boatInfosValue}>
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    _isWithinFifteenMinutes(date) {
        const now = new Date(); // Obtenir l'heure actuelle
        const fifteenMinutesAgo = new Date(now.getTime() - 15 * 60 * 1000); // Calculer l'heure il y a 15 minutes

        return date >= fifteenMinutesAgo && date <= now; // Vérifier si la date est dans l'intervalle
    }

    _formatDateFrancais(date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Utiliser le format 24 heures
        };
        return new Intl.DateTimeFormat('fr-FR', options).format(date);
    }


    _renderModal() {
        const { classes, isMobile, boat, aisData, currentWeather } = this.props
        const { selectedBoat, boats } = this.state

        if (!selectedBoat) return null

        if (!boat)
            return (
                <div className={classes.modalMapRoot}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <BoatOnLoading
                            description={this.displayText('loading')}
                        />
                    </div>
                </div>
            )

        const url = window.location.href
        const lastNav = this._getLastNavigation(boat?.id)
        const currentBoatData = boats?.find(boat => boat.id === selectedBoat)
        console.log("currentBoatData")
        console.log(currentBoatData)

        let ais = null
        let distance = null
        let speed = null
        let maxSpeed = null

        if (lastNav?.isAisUsed && boat?.mmsi) {
            ais = aisData?.find(data => data.navigationId === lastNav.id)
                ?.aisData
        }
        if (ais && ais.length > 0) {
            const formattedLatLon = ais.reduce(
                (list, data) => [...list, [data.latitude, data.longitude]],
                [],
            )

            distance = getDistanceFromCoordinates(formattedLatLon)
            //convert km to miles
            distance = Math.round(distance * 0.539957 * 100) / 100

            const totalSpeed = ais.reduce((totalSpeed, data) => {
                if (!maxSpeed || maxSpeed < parseFloat(data.speed))
                    maxSpeed = parseFloat(data.speed)
                return totalSpeed + parseFloat(data.speed)
            }, 0)

            speed = parseFloat(totalSpeed / ais.length).toFixed(2)
        }
        const lastNavData = [
            {
                label: this.displayText('durationNav'),
                value: lastNav ? getNavigationDurationTime(lastNav) : null,
                unit: '',
            },
            {
                label: this.displayText('distanceNav'),
                value: distance,
                unit: this.displayText('nauticMille'),
            },
            {
                label: this.displayText('averageSpeed'),
                value: speed,
                unit: 'kn',
            },
            {
                label: this.displayText('maxSpeed'),
                value: maxSpeed,
                unit: 'kn',
            },
        ]

        let boatImage = null

        if (
            boat?.files &&
            boat.files?.length > 0 &&
            boat.files[boat.files.length - 1].link
        ) {
            boatImage = `${API_URL}/files/${
                boat.files[boat.files.length - 1].link
            }`
        }

        return (
            <div
                className={classes.modalMapRoot}
                style={isMobile ? { width: '100%' } : undefined}
            >
                <div className={classes.imageContainer}>
                    <img
                        className={classes.modalMapImage}
                        src={boatImage}
                        alt=""
                    />
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={() => this.setState({ selectedBoat: null })}
                    />
                </div>
                <div className={classes.modalBoatName}>{boat?.name}</div>
                <div className={classes.liveInfosContainer}>
                    {!currentBoatData.lastCoordinates.lastSignalDate ? (
                            <div className={classes.liveInfosTitle}>
                                {this.displayText('noSignal')}
                            </div>
                        ) : this._isWithinFifteenMinutes(currentBoatData.lastCoordinates.lastSignalDate) ? (
                            <div className={classes.liveInfosTitle}>
                                {this.displayText('liveInfos')}
                                <div className={classes.liveInfosTitleDot} />
                            :
                        </div>
                    ) : (
                        <div className={classes.liveInfosTitle}>
                            {this.displayText('lastSignal') + this._formatDateFrancais(currentBoatData.lastCoordinates.lastSignalDate)}
                        </div>
                    )
                    }

                    <div className={classes.liveInfosData}>
                        <div className={classes.liveInfosItem}>
                            <div className={classes.liveInfosItemValue}>
                            {currentBoatData.lastCoordinates.speed || '-'}{' '}
                                kn
                            </div>
                            <div className={classes.liveInfosItemLabel}>
                                {this.displayText('speed')}
                            </div>
                        </div>

                        <div className={classes.modalDataItem}>
                            <div className={classes.modalDataItemData}>
                                {currentBoatData.lastCoordinates.heading || '-'}{' '}
                                °
                            </div>
                            <div className={classes.modalDataItemLabel}>
                                {this.displayText('heading')}
                            </div>
                        </div>
                    </div>
                </div>
                {lastNav && (
                    <Accordion classes={{ root: classes.accordion }}>
                        <AccordionSummary
                            classes={{
                                root: classes.accordionSummary,
                                contentGutters: classes.accordionSummary,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {this.displayText('lastNavigation')}
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{ root: classes.accordionDetails }}
                        >
                            <div className={classes.fullWidth}>
                                <div className={classes.modalLastNavData}>
                                    {lastNavData.map((item, index) =>
                                        item.value !== null ? (
                                            <div
                                                className={
                                                    classes.modalDataItem
                                                }
                                                key={`data-item-${index}`}
                                            >
                                                <div
                                                    className={
                                                        classes.modalDataItemData
                                                    }
                                                >
                                                    {item.value} {item.unit}
                                                </div>
                                                <div
                                                    className={
                                                        classes.modalDataItemLabel
                                                    }
                                                >
                                                    {item.label}
                                                </div>
                                            </div>
                                        ) : null,
                                    )}
                                </div>
                                <div className={classes.stepper}>
                                    {this._renderStepperModal(lastNav, true)}
                                    {this._renderStepperModal(lastNav, false)}
                                </div>
                                <ButtonBON
                                    classNameOverride={classes.buttonLogBook}
                                    label={this.displayText('seeLogBook')}
                                    href={
                                        lastNav ? `${url}/${lastNav.id}` : url
                                    }
                                    fullWidth
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
                {lastNav && (
                    <Accordion classes={{ root: classes.accordion }}>
                        <AccordionSummary
                            classes={{
                                root: classes.accordionSummary,
                                contentGutters: classes.accordionSummary,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {this.displayText('crew')}
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{ root: classes.accordionDetails }}
                        >
                            {this._renderCrew(lastNav)}
                        </AccordionDetails>
                    </Accordion>
                )}
                {lastNav?.stopOvers && lastNav.stopOvers.length > 0 && (
                    <Accordion classes={{ root: classes.accordion }}>
                        <AccordionSummary
                            classes={{
                                root: classes.accordionSummary,
                                contentGutters: classes.accordionSummary,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {this.displayText('stopovers')}
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{ root: classes.accordionDetails }}
                        >
                            {this._renderStopovers(lastNav)}
                        </AccordionDetails>
                    </Accordion>
                )}
                {currentWeather && (
                    <Accordion classes={{ root: classes.accordion }}>
                        <AccordionSummary
                            classes={{
                                root: classes.accordionSummary,
                                contentGutters: classes.accordionSummary,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {this.displayText('weather')}
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{ root: classes.accordionDetails }}
                        >
                            {this._renderWeather()}
                        </AccordionDetails>
                    </Accordion>
                )}
                <Accordion classes={{ root: classes.accordion }}>
                    <AccordionSummary
                        classes={{
                            root: classes.accordionSummary,
                            contentGutters: classes.accordionSummary,
                        }}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        {this.displayText('fillingLevels')}
                    </AccordionSummary>
                    <AccordionDetails
                        classes={{ root: classes.accordionDetails }}
                    >
                        {this._renderFillingLevels(lastNav)}
                    </AccordionDetails>
                </Accordion>
                <Accordion classes={{ root: classes.accordion }}>
                    <AccordionSummary
                        classes={{
                            root: classes.accordionSummary,
                            contentGutters: classes.accordionSummary,
                        }}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        {this.displayText('workingHours')}
                    </AccordionSummary>
                    <AccordionDetails
                        classes={{ root: classes.accordionDetails }}
                    >
                        {this._renderWorkingHours(lastNav)}
                    </AccordionDetails>
                </Accordion>
                <Accordion classes={{ root: classes.accordion }}>
                    <AccordionSummary
                        classes={{
                            root: classes.accordionSummary,
                            contentGutters: classes.accordionSummary,
                        }}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        {this.displayText('boatInfos')}
                    </AccordionSummary>
                    <AccordionDetails
                        classes={{ root: classes.accordionDetails }}
                    >
                        {this._renderBoatInfos()}
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }

    render() {
        const { classes, isMobile } = this.props
        const { selectedModal, boats, drawAis, mapInfos } = this.state

        const boatsMarkers = this._getBoatsMarkers()
        const boatsTraces = drawAis ? this._getBoatsTraces() : null
        let map = null

        if (boats === null)
            return (
                <BoatOnLoading
                    description={`${this.displayText('loading')} ...`}
                />
            )
        else if (mapInfos?.minPoint?.lat) {
            map = (
                <div
                    className={
                        isMobile
                            ? `${classes.mapContainer} ${classes.mapContainerMobile}`
                            : classes.mapContainer
                    }
                    key="mapContainer"
                >
                    <MapContainer
                        minZoom={2}
                        center={mapInfos.center}
                        bounds={[
                            [mapInfos.minPoint.lat, mapInfos.minPoint.lon],
                            [mapInfos.maxPoint.lat, mapInfos.maxPoint.lon],
                        ]}
                        ref={this.mapRef}
                    >
                        <TileLayer
                            attribution={`&copy;
                            <a href="https://www.openstreetmap.org/copyright">
                                OpenStreetMap
                            </a> contributors`}
                            url={
                                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            }
                        />
                        {boatsMarkers}
                        {boatsTraces}
                    </MapContainer>
                    <RouteIcon
                        className={
                            drawAis
                                ? `${classes.routeIcon} ${classes.activatedIcon}`
                                : classes.routeIcon
                        }
                        style={{
                            top: 30,
                            transform: 'rotate(90deg)',
                        }}
                        onClick={this.switchDrawAis}
                    />
                    {this._renderModal()}
                </div>
            )
        } else {
            map = (
                <BoatOnLoading
                    description={`${this.displayText('loading')} ...`}
                />
            )
        }

        return (
            <div className={classes.root}>
                <div className={classes.filterContainer}>
                    <BobFilter
                        activeButton={{
                            boatsByFloat: true,
                            navigationStatus: true,
                        }}
                        noDivider
                        rightComponent={
                            <ButtonBON
                                variant="contained"
                                color="primary"
                                label={this.displayText('newNavigation')}
                                size={`large`}
                                fullWidth={isMobile ? true : false}
                                onClick={this.openNewNavigation}
                            />
                        }
                    />
                </div>
                {map}
                <LogBookCreateNav
                    selectedModal={selectedModal}
                    closeNavigation={this.closeNewNavigation}
                />
            </div>
        )
    }
}

function filterBoats(bobFilter, boats, navigations) {
    if (
        bobFilter.selectedBoatsByFleet &&
        bobFilter.selectedBoatsByFleet.length > 0
    ) {
        boats = boats.filter(boat =>
            bobFilter.selectedBoatsByFleet.includes(boat.id),
        )
    }

    if (bobFilter.searchString) {
        const boatsTodisplay = []
        boats.forEach(boat => {
            if (
                (boat.name &&
                    boat.name
                        .trim()
                        .toLowerCase()
                        .includes(
                            bobFilter.searchString.trim().toLowerCase(),
                        )) ||
                (boat.maker &&
                    boat.maker
                        .trim()
                        .toLowerCase()
                        .includes(
                            bobFilter.searchString.trim().toLowerCase(),
                        )) ||
                (boat.mmsi &&
                    boat.mmsi
                        .trim()
                        .toLowerCase()
                        .includes(
                            bobFilter.searchString.trim().toLowerCase(),
                        )) ||
                (boat.model &&
                    boat.model
                        .trim()
                        .toLowerCase()
                        .includes(
                            bobFilter.searchString.trim().toLowerCase(),
                        )) ||
                (boat.address?.fullAddress &&
                    boat.address.fullAddress
                        ?.trim()
                        .toLowerCase()
                        .includes(bobFilter.searchString.trim().toLowerCase()))
            ) {
                boatsTodisplay.push(boat)
            }
        })

        boats = boatsTodisplay
    }

    if (
        bobFilter.navigationStatusChecked &&
        bobFilter.navigationStatusChecked.length > 0
    ) {
        const boatsInProgress = new Set([])
        const neededBoat = []

        navigations.forEach(nav => {
            if (
                nav.nbDepartingPassenger !== null &&
                nav.delimitedDate.startDate !== null &&
                nav.delimitedDate.endDate === null
            ) {
                boatsInProgress.add(nav.boat.id)
            }
        })

        if (
            bobFilter.navigationStatusChecked.includes('navigationInProgress')
        ) {
            boats.forEach(boat => {
                const boatFound = [...boatsInProgress].find(b => b === boat.id)

                if (boatFound) neededBoat.push(boat)
            })
        }

        if (bobFilter.navigationStatusChecked.includes('navigationStopped')) {
            boats.forEach(boat => {
                const boatFound = [...boatsInProgress].find(b => b === boat.id)

                if (!boatFound) neededBoat.push(boat)
            })
        }

        boats = neededBoat
    }

    return boats
}

function mapStateToProps(state) {
    let boats = state.group?.groupsMembers?.boats || []
    const navigations = state.logbook.navigations
    const isMobile = window.innerWidth < 600

    boats = filterBoats(state.filter.bobFilter, boats, navigations)

    return {
        isMobile,
        context: getContextFromUrl(window.location.pathname),
        boats,
        boat: state.bob.boat,
        navigations,
        user: state.authentication.user,
        events: state.bob.events,
        loadingNav: state.logbook.loading,
        loadingAis: state.navigationApiData.loading,
        groupId: state.group?.currentGroupId,
        aisData: state.navigationApiData?.ais,
        currentWeather: state.navigationApiData?.currentWeather,
        lastAis: state.navigationApiData?.lastAis || [],
        aisByGroup: state.navigationApiData?.aisByGroup || [],
        bobFilter: state.filter.bobFilter,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        userEquipments: state.types?.userEquipments || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookMap))
