import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnAutoCompleteCss'
import { Autocomplete } from '@material-ui/lab'
import {
    TextField,
    Divider,
    Typography,
    Tooltip,
    Chip, IconButton,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Actions, PermissionsEntities } from '../../constants'
import AttachFile from '@material-ui/icons/AttachFile'
import BoatOnBlock from './Blocks/BoatOnBlock'

class BoatOnAutoComplete extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.addButtonToList = this.addButtonToList.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.renderTagsCondition = this.renderTagsCondition.bind(this)
    }

    handleChange(e, value) {
        const { indexAutoComplete, id, onChange } = this.props

        if (
            (!value || !value.button) &&
            (!value || !value[0] || !value[0].button)
        ) {
            onChange(e, value, { indexAutoComplete, id })
        }
    }

    renderTagsCondition(value, getTagProps) {
        const { renderTagCondition, helpGreyChip } = this.props

        return value.map((option, index) => {
            if (option) {
                return (
                    <Tooltip
                        disableHoverListener={!renderTagCondition(option)}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">
                                    {helpGreyChip || ``}
                                </Typography>
                            </React.Fragment>
                        }
                        key={index}
                    >
                        {this._renderChip(option, getTagProps, index)}
                    </Tooltip>
                )
            }
            return <></>
        })
    }

    _renderChip(option, getTagProps, index) {
        const { renderTagCondition } = this.props
        return (
            <Chip
                size="medium"
                variant="outlined"
                style={{
                    backgroundColor: !renderTagCondition(option)
                        ? '#fcd48e'
                        : '#c4c4c4',
                }}
                label={this.props.getName(option)}
                {...getTagProps({
                    index,
                })}
            />
        )
    }

    addButtonToList(options, input) {
        const {
            classes,
            labelAdd,
            onAddButtonClicked,
            addProps,
            filterOptions,
            groupBy,
            addButton,
            addPermission
        } = this.props
        const result = [
            ...options.filter(
                option => !filterOptions || filterOptions(option, input),
            ),
        ]

        if (addButton){
            if(addPermission){
                result.push({
                    button: true,
                    element: (
                        <BoatOnBlock
                            permissions={addPermission}
                            children={
                                <div
                                    className={classes.buttonAdd}
                                    onClick={onAddButtonClicked}
                                    {...addProps}
                                >
                                    <Divider className={classes.divider} />
                                    <div className={classes.flexRow}>
                                        <Add
                                            className={
                                                !groupBy
                                                    ? classes.plus
                                                    : classes.plusWithCategory
                                            }
                                        />
                                        <Typography>{labelAdd}</Typography>
                                    </div>
                                </div>
                            }
                        />
                    ),
                })
            } else {
                result.push({
                    button: true,
                    element: (
                        <div
                            className={classes.buttonAdd}
                            onClick={onAddButtonClicked}
                            {...addProps}
                        >
                            <Divider className={classes.divider} />
                            <div className={classes.flexRow}>
                                <Add
                                    className={
                                        !groupBy
                                            ? classes.plus
                                            : classes.plusWithCategory
                                    }
                                />
                                <Typography>{labelAdd}</Typography>
                            </div>
                        </div>
                    ),
                })
            }
        }

        return result
    }

    renderTags(value, getTagProps) {
        const { classes, getElementToShow } = this.props // TODO: remove tooltip quand pas util

        return value.map((option, index) =>
            option ? (
                <Tooltip
                    className={classes.tooltip}
                    title={
                        <React.Fragment>
                            <Typography color="inherit">
                                {option.userPart // TODO: changer la condition quand il y aura le lien avec detail
                                    ? this.displayText('addInInventoryPart')
                                    : this.displayText('editInventoryPart')}
                            </Typography>
                        </React.Fragment>
                    }
                    key={index}
                >
                    <Chip
                        size="medium"
                        variant="outlined"
                        clickable
                        style={{
                            backgroundColor: option.userPart // TODO: changer la condition quand il y aura le lien avec detail
                                ? '#c4c4c4'
                                : '#fcd48e',
                        }}
                        label={getElementToShow(option)}
                        {...getTagProps({
                            index,
                        })}
                    />
                </Tooltip>
            ) : (
                <></>
            ),
        )
    }

    render() {
        const {
            placeholder,
            inputClass,
            index,
            conditionAlready,
            getElementToShow,
            error,
            helperText,
            multiple,
            label,
            renderTags,
            margin,
            renderTagCondition,
            onInputChange,
            textFieldRef,
            disabled,
            classes,
            useOneTag,
        } = this.props

        const autocompleteProps = {
            ...this.props,
            multiple: multiple || useOneTag,
        }
        // Nettoyage des props à passer au composant Autocomplete pour eviter des erreurs
        delete autocompleteProps.getElementToShow
        delete autocompleteProps.onAddButtonClicked
        delete autocompleteProps.labelAdd
        delete autocompleteProps.inputClass
        delete autocompleteProps.addButton
        delete autocompleteProps.conditionAlready
        delete autocompleteProps.addProps
        delete autocompleteProps.helperText
        delete autocompleteProps.renderTagCondition
        delete autocompleteProps.helpGreyChip
        delete autocompleteProps.getName
        delete autocompleteProps.classes
        delete autocompleteProps.useOneTag

        return (
            <Autocomplete
                {...autocompleteProps}
                id="autoCompletion"
                renderTags={
                    renderTagCondition
                        ? this.renderTagsCondition
                        : multiple && (renderTags || this.renderTags)
                }
                getOptionDisabled={conditionAlready}
                clearOnBlur
                onChange={this.handleChange}
                getOptionLabel={getElementToShow}
                onInputChange={onInputChange}
                sx={{ width: 300 }}
                renderInput={params => (
                    <TextField
                        {...params}
                        className={inputClass}
                        id={`partEquipment${index}`}
                        placeholder={placeholder}
                        margin={margin || `dense`}
                        variant="outlined"
                        error={error}
                        helperText={helperText}
                        label={label}
                        required={this.props.required}
                        InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                        ref={textFieldRef}
                        classes={{
                            root: this.props.classes.inputRootOverride,
                        }}
                    />
                )}
                filterOptions={this.addButtonToList}
                renderOption={(option, index) => {
                    if (option.button) return option.element
                    return (
                        <div key={index}>
                            {getElementToShow
                                ? getElementToShow(option)
                                : option.value}
                        </div>
                    )
                }}
                className={classes.autoComplete}
            />
        )
    }
}

export default withStyles(styles)(BoatOnAutoComplete)
